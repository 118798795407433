<template>
  <div class="has-background-white timeline is-mobile">
    <ModalContainer v-if="finishFirstRender"> </ModalContainer>
    <div class="head margin-1">
      <div class="level padding-10 is-mobile">
        <FilterBar />
        <CategoryList v-if="finishFirstRender"/>
      </div>
    </div>
    <DifferentDayWarning class="margin-1" v-if="finishFirstRender && !isTodaySelected"/>
    
    <div class="columns is-gapless timeline-data is-mobile">
      <StylistColumn />
      <div class="column data-table" v-if="timeline && stylists.length && hours.length" id="data-table" v-dragscroll:nochilddrag="isHourRowHovered"> <!-- v-dragscroll:nochilddrag -->
          <HourRow v-if="fastStylists.length" @mousedown="isHourRowHovered = true" :is20Minutes="true"/>
          
          <div
            v-for="stylist in fastStylists"
            :key="stylist.id"
            class="row columns is-mobile is-gapless clickable main-data-table"
            @mousedown="isHourRowHovered = false"
          >
			      <div
              class="cell placeholder-cell-20-minutes"
            ></div>
            <div
              v-for="hour in hours.filter(hour => hour.is20Minutes)"
              :key="hour.subHourId"
              class="cell column is-cell-20-minutes"
              v-bind:class="{'is-disabled': stylist.isActive[hour.subHourId] != true }"
            >
              <div class="columns is-gapless" v-if="timeline[stylist.id][hour.subHourId].length">
                <div
                  v-for="(booking, index) in timeline[stylist.id][hour.subHourId].map(bookingId => bookingMap[bookingId])"
                  :key="`${stylist.id}${hour.subHourId}${index}`"

                  v-drag-and-drop
                  :draggable="booking.completeBillTime === null"
                  class="is-dragable column"
                  @drop="handleDrop(booking)"
                  @drag="draggedBooking = booking; isDragging = true;"
                  @mouseup="isDragging = false"
                >
                  <BookedCard
                    v-if="booking.customerPhone"
                    :detectedInfo="recognition.detectedCustomers[booking.customerPhone]"
                    :bookingId="booking.id"
                    :cellShared="timeline[stylist.id][hour.subHourId].length==2"
                    class="tooltip-target"
                    :class="{'is-printBilled': booking.completeBillTime !== null}"
                    @evaluate-recognition="onEvaluate"
                  ></BookedCard>
                </div>
              </div>
              <div
                v-else
                v-drag-and-drop
                @drop="handleDrop({ stylistId: stylist.id, subHourId: hour.subHourId})"
              >
                <BookCard
                  v-if="stylist.isActive[hour.subHourId] == true"
                  class="absolute"
                  :booking="{ stylistId: stylist.id, subHourId: hour.subHourId }"
                ></BookCard>
              </div>
            </div>
          </div>
          
          <HourRow v-if="normalStylists.length" @mousedown="isHourRowHovered = true"/>

          <div
            v-for="stylist in normalStylists"
            :key="stylist.id"
            class="row columns is-mobile is-gapless clickable main-data-table"
            @mousedown="isHourRowHovered = false"
          >
            <div
              v-for="hour in hours.filter(hour => !hour.is20Minutes)"
              :key="hour.subHourId"
              class="cell column"
              v-bind:class="{'is-disabled': stylist.isActive[hour.subHourId] != true }"
            >
              <div class="columns is-gapless" v-if="timeline[stylist.id][hour.subHourId].length">
                <div
                  v-for="(booking, index) in timeline[stylist.id][hour.subHourId].map(bookingId => bookingMap[bookingId])"
                  :key="`${stylist.id}${hour.subHourId}${index}`"

                  v-drag-and-drop
                  :draggable="booking.completeBillTime === null"
                  class="is-dragable column"
                  @drop="handleDrop(booking)"
                  @drag="draggedBooking = booking; isDragging = true;"
                  @mouseup="isDragging = false"
                >
                  <BookedCard
                    v-if="booking.customerPhone"
                    :detectedInfo="recognition.detectedCustomers[booking.customerPhone]"
                    :bookingId="booking.id"
                    :cellShared="timeline[stylist.id][hour.subHourId].length==2"
                    class="tooltip-target"
                    :class="{'is-printBilled': booking.completeBillTime !== null}"
                    @evaluate-recognition="onEvaluate"
                  ></BookedCard>
                </div>
              </div>
              <div
                v-else
                v-drag-and-drop
                @drop="handleDrop({ stylistId: stylist.id, subHourId: hour.subHourId})"
              >
                <BookCard
                  v-if="stylist.isActive[hour.subHourId] == true"
                  class="absolute"
                  :booking="{ stylistId: stylist.id, subHourId: hour.subHourId }"
                ></BookCard>
              </div>
            </div>
          </div>
          <HourRow v-if="normalStylists.length" @mousedown="isHourRowHovered = true"/>
      </div>
      <!--<div v-else class="hero">
        <div class="hero-body" v-if="!isLoading && selectedSalon && selectedSalon.id">
          <h1 class="title">Salon chưa có dữ liệu chấm công</h1>
        </div>
      </div>-->
    </div>

    
    <b-loading :is-full-page="true" :active.sync="isLoading" :can-cancel="true"></b-loading>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import moment from "moment";
import axios from "axios";

import BookCard from "@/components/BookCard";
import BookedCard from "@/components/BookedCard";
import convertUtil from "@/utils/convert";

import {
  FilterBar,
  StylistColumn,
  HourRow
} from "@/components/Timeline";

const ModalContainer = () => import('@/components/Timeline/ModalContainer');
const DifferentDayWarning = () => import('@/components/Timeline/DifferentDayWarning');
const CategoryList = () => import('@/components/Timeline/CategoryList');

import apiConfig from "@/configs/api";
import websocketUtil from "@/utils/websocket";

import notiUtil from "@/utils/noti";

export default {
  components: {
    BookCard,
    BookedCard,
    CategoryList,
    FilterBar,
    StylistColumn,
    HourRow,
    DifferentDayWarning,
    ModalContainer
  },
  name: "Home",

  data() {
    return {
      finishFirstRender: false,
      isLoading: false,
      draggedBooking: null,
      detectedCustomerPhones: [],
      isHourRowHovered: false,
      recognition: {
        isProducerConnected: null,
        isConsumerConnected: null,
        producerConnection: null,
        consumerConnection: null,
        isStreaming: null,
        /*detectedCustomers: {
          "0839684434": {
            person_id: 1,
            info: {
              Name: "Tien Anh",
              Id: 68747,
              Phone: "0839684434"
            },
            image_urls: [
              "https://s3-ap-southeast-1.amazonaws.com/30shine/OldImages/Customer2/2017/11/1/5/img_804595_ncWvTfeNTA.JPG",
              "https://s3-ap-northeast-1.amazonaws.com/30shine.faces-test/tests/tienanh.jpg",
              "https://s3-ap-northeast-1.amazonaws.com/30shine.faces-test/tests/tienanh.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/OldImages/Customer2/2017/11/1/5/img_804595_ncWvTfeNTA.JPG",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_03_23/702DL_1429551_ios_1.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_08_21/702DL_2407970_ios_1.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_10_18/2810574_andr_378_378_0.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2019_01_25/702DL_259_259_3634935_ios_1.jpg"
            ],
            detected_faces: ["aaa"],
            confidence: 0.823467,
            booking_info: {
              HourFrame: "12:00:00",
              CustomerName: "Tien Anh",
              CustomerId: 68747
            }
          },
          "0903456024": {
            person_id: 1,
            info: {
              Name: "Tien Anh",
              Id: 68747,
              Phone: "0839684434"
            },
            image_urls: [
              "https://s3-ap-southeast-1.amazonaws.com/30shine/OldImages/Customer2/2017/11/1/5/img_804595_ncWvTfeNTA.JPG",
              "https://s3-ap-northeast-1.amazonaws.com/30shine.faces-test/tests/tienanh.jpg",
              "https://s3-ap-northeast-1.amazonaws.com/30shine.faces-test/tests/tienanh.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/OldImages/Customer2/2017/11/1/5/img_804595_ncWvTfeNTA.JPG",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_03_23/702DL_1429551_ios_1.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_08_21/702DL_2407970_ios_1.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2018_10_18/2810574_andr_378_378_0.jpg",
              "https://s3-ap-southeast-1.amazonaws.com/30shine/app_staff/2019_01_25/702DL_259_259_3634935_ios_1.jpg"
            ],
            detected_faces: ["aaa"],
            confidence: 0.823467,
            booking_info: {
              HourFrame: "12:00:00",
              CustomerName: "Tien Anh",
              CustomerId: 68747
            }
          }
        }*/
        
        detectedCustomers: {}
      },
      socket: null
    };
  },
  computed: {
    ...mapGetters([
      "timeline",
      "hours",
      "stylists",
      "bookings",
      "user",
      "dateNormal",
      "timelineFilter",
      "hourMap",
      "stylistMap",
      "recognitionSocket",
      "bookingMap",
      "isTodaySelected",
      "fastStylists",
      "normalStylists"
    ]),
    selectedSalon() {
      return this.timelineFilter.salon;
    },

    enableFaceRecognition() {
      return this.timelineFilter.enableFaceRecognition;
    },
    isDragging: {
      get() {
          return this.$store.getters.isDragging;
      },
      set(value) {
          this.$store.commit("setDragging", value);
      }
    }
  },

  methods: {
    ...mapActions(["setHours", "getStylists", "setSkinners", "setBookings", "setCheckins", "updateBookingsToTimeline", "resetTimeline", "setServices", "setProducts", "setLinearFlowExperimentSalons"]),
    ...mapMutations(["setDragging", "emptyTimeline"]),
    async fetchData() {
      let date = moment(this.dateNormal);

      if (this.selectedSalon) {
        this.isLoading = true;
        localStorage.setItem("salonId", this.selectedSalon.id);

        const payload = {
          salonId: this.selectedSalon.id,
          date
        };
        
        this.emptyTimeline();
        await Promise.all([this.getStylists(payload), this.setHours(this.selectedSalon.id), this.setBookings(payload)]);
        this.isLoading = false;
        
        this.addTimekeepingTypeChangedStylists();
        this.resetTimeline();

        ///this.updateBookingsToTimeline();
        this.bookings.forEach(booking => {
          this.$store.dispatch("addToTimeline", booking);
        })
        this.finishFirstRender = true;
        console.log('start adding checkins, services, products');
        Promise.all([this.setSkinners(payload), this.setCheckins(payload), this.setServices(this.selectedSalon.id), this.setProducts(this.selectedSalon.id)/*, this.setLinearFlowExperimentSalons()*/]).then();
        
      }
    },
    mapBooking() {
      this.$store.dispatch("resetTimeline");
      if (typeof this.bookings != "string")
        this.bookings.forEach(booking => {
          this.$store.dispatch("addToTimeline", booking);
        });
    },

    // stylist change timekeeping to another timeline but has booking in the previous timeline
    addTimekeepingTypeChangedStylists() {
      this.bookings.forEach((booking, i) => {
        let newStylistId = null;
        const stylist = this.stylists[this.stylistMap[booking.stylistId]];

        if (booking.subHourId.includes('sub2') && !stylist.secondSubWorkHour) {
          newStylistId = `sub2.${stylist.id}`
        } else if (!booking.subHourId.includes('sub2') && stylist.secondSubWorkHour) {
          newStylistId = `sub.${stylist.id}`
        }

        if (newStylistId) {
          const newBooking = {
            ...booking,
            stylistId: newStylistId
          }

          if (!this.stylistMap[newStylistId]) { // if new stylist has not been added
            const newStylist = {
              ...stylist,
              id: newStylistId,
              isActive: {}
            }
            newStylist.secondSubWorkHour = newStylistId.startsWith('sub2');
            this.$store.commit('setStylists', [...this.stylists, newStylist]);
          }
          
          this.$store.commit('updateBooking', newBooking);
        }
      })
    },


    async handleDrop(booking) {
      const hourI = this.hourMap[booking.subHourId];
      const stylistI = this.stylistMap[booking.stylistId];
      this.isDragging = false;
      let message = [];

      const db = this.draggedBooking;

      // drag to the same position
      if (
        booking.stylistId == db.stylistId &&
        booking.subHourId == db.subHourId
      ) {
        return;
      }

      // hour is full
      if (!this.$store.getters.isSlotOpen(booking.stylistId, booking.subHourId)) {
        notiUtil.generalError(this, "Đã kín lịch, vui lòng chọn khung giờ khác");
        return;
      }

      if (db.completeBillTime) {
        message.push("Bill đã hoàn thành, không thể đổi giờ!");
      } else if (db.isMakeBill) {
        if (db.hourId !== this.hours[hourI].hourId) message.push("Đã in hóa đơn, không thể đổi giờ!");
        
        const diss = db.subHourId.includes('sub2'); // Dragged booking Is Second Sub
        const tiss = booking.subHourId.includes('sub2'); // Target booking Is Second Sub

        /*if (diss && tiss && db.subHourId != booking.subHourId) {
          message.push("Đã in bill, không được đổi khung giờ 20 phút");
        }*/

        
        if (diss != tiss) {
          const draggedHourI = this.hourMap[db.subHourId];
          /*if (this.hours[hourI].hourFrame != this.hours[draggedHourI].hourFrame) {
            message.push("Đổi bill đã in giữa khung giờ 20p và 15p, chỉ có thể đổi các khung giờ chẵn 00 như 16:00, 17:00");
          }*/
          if (!this.hours[hourI].parentSubHourId == this.hours[draggedHourI].parentSubHourId) {
            message.push("Khi đổi lịch chéo khung giờ, chỉ được phép đổi 00, 15, 20 hoặc 30, 40, 45 với nhau");
          }
        }
      }
      console.log('length of message', message.length)
      if (message.length > 0) {
        for (let i = 0; i < message.length; i++) {
          this.$notify({
            group: "foo",
            type: "error",
            title: "Hệ thống",
            text: `${message[i]}`
          });
        }
        return;
      }

      this.$buefy.dialog.confirm({
        message: '<h1 class="title is-4">Xác nhận đổi lịch</h1>',
        onConfirm: async () => {
          const bookingId = db.id;
          try {
            let data = {
              customerPhone: db.customerPhone,
              salonId: this.selectedSalon.id,
              stylistId: this.stylists[stylistI].id,
              hourId: this.hours[hourI].hourId,
              subHourId: this.hours[hourI].subHourId,
              isBookStylist: db.isBookStylist,
              dateBook: this.$store.getters.selectedMomentDate.format('YYYY-MM-DD')
            };
            
            
            this.$store.dispatch("updateBooking", {
              id: bookingId,
              subHourId: data.subHourId,
              hourId: data.hourId,
              stylistId: data.stylistId
            });


            this.$store.commit("setDeferAction", { bookingId, shouldDefer: true });

            this.draggedBooking = null;
            
            data = convertUtil.transformSubHourOut(data)
            console.time('updateBooking')
            const { data: booking } = await axios.put(
              `${apiConfig.MAIN_API}/api/booking?bookingId=${bookingId}`,
              data
            );
            console.timeEnd('updateBooking')
          } catch (e) {
            notiUtil.changeBookingError(this, e.response.data);
            console.log(e);

            // rollback pre update
            this.$store.dispatch("updateBooking", {
              id: bookingId,
              subHourId: db.subHourId,
              hourId: db.hourId,
              stylistId: db.stylistId
            });
          } finally {
            this.$store.commit("setDeferAction", { bookingId, shouldDefer: false });
          }
        }
      });
    },

    handlePersonList(data) {
      const minAccuracy = this.timelineFilter.minAccuracy;
      const detectedCustomers = {};
      data.forEach(item => {
        if (item["confidence"] > minAccuracy / 100) {
          detectedCustomers[item["info"]["Phone"]] = item;
        }
      });
      this.recognition.detectedCustomers = detectedCustomers;
      // console.log(data);
    },

    handleMessage(message) {
      switch (message.type) {
        case "persons":
          this.handlePersonList(message.data);
          break;
        /*case "frame":
          this.handleFrame(message.data);
          break;*/
      }
    },

    listenToRecognition() {
      console.log("recognitionSocket", this.recognitionSocket);
      if (!this.recognitionSocket || !this.recognitionSocket.Host) return;

      let host = this.recognitionSocket.Host;
      let consumerPort = this.recognitionSocket.ConsumerPort;
      let producerPort = this.recognitionSocket.ProducerPort;

      console.log(`hostUrl is ${host}`);
      console.log("Connecting to video stream");

      const startConnectingTime = Date.now();
      let lastMessageTime = {
        frame: Date.now(),
        persons: Date.now()
      };
      try {
        var consumerConnection = new WebSocket(
          websocketUtil.getConsumerUrl(host, consumerPort)
        );

        consumerConnection.onerror = error => {
          console.log("WebSocket Consumer Error ", typeof error);
          console.log("Fail after", Date.now() - startConnectingTime);
          this.recognition.isConsumerConnected = false;
          const recognitionError = "Cannot connect to websocket";
          this.$store.commit("setRecognitionError", recognitionError);
        };

        consumerConnection.onopen = e => {
          console.log("consumer opened");
          this.recognition.isConsumerConnected = true;
        };

        consumerConnection.onclose = e => {
          console.log("consumer closed at " + Date.now());
        };

        consumerConnection.onmessage = e => {
          const rawMessage = e.data;
          const message = JSON.parse(rawMessage);
          lastMessageTime[message.type] = Date.now();
          this.handleMessage(message);
        };
        this.recognition.consumerConnection = consumerConnection;

        var producerConnection = new WebSocket(
          websocketUtil.getProducerUrl(host, producerPort)
        );

        producerConnection.onopen = e => {
          console.log("producer open");
          producerConnection.send(
            JSON.stringify({
              type: "system",
              message: "Ping!"
            })
          );
          this.recognition.isProducerConnected = true;
          const recognitionError = "Cannot connect to websocket";
          this.$store.dispatch("setRecognitionError", recognitionError);
        };

        producerConnection.onclose = e => {
          console.log("producer closed at " + Date.now());
        };

        producerConnection.onerror = error => {
          console.log("WebSocket Producer Error ", error);
          this.recognition.isProducerConnected = false;
        };
        this.recognition.producerConnection = producerConnection;

        let checkConnectionInterval = setInterval(() => {
          if (
            this.recognition.isProducerConnected &&
            this.recognition.isConsumerConnected
          ) {
            const now = Date.now();
            if (
              (now - lastMessageTime.persons > 3000 ||
                (this.recognition.isStreaming &&
                  now - lastMessageTime.frame > 3000)) &&
              now - startConnectingTime > 30000
            ) {
              console.log("Camera idle. Reloading...");
              // window.location.reload();
            }
          }
        }, 3000);
      } catch (error) {
        console.log(JSON.stringify(error, 0, 2));
      }
    },
    disableRecognition() {
      if (this.recognition.producerConnection.readyState === WebSocket.OPEN)
        this.recognition.producerConnection.close();
      if (this.recognition.consumerConnection.readyState === WebSocket.OPEN)
        this.recognition.consumerConnection.close();

      this.recognition = {
        isProducerConnected: null,
        isConsumerConnected: null,
        producerConnection: null,
        consumerConnection: null,
        isStreaming: null,
        detectedCustomers: {},
        lastCloseTime: Date.now()
      };
    },

    onEvaluate({ evaluation, data }) {
      if (!this.recognition.producerConnection) return;
      console.log(evaluation, data);
      this.recognition.producerConnection.send(
        JSON.stringify({
          type: "evaluation",
          message: {
            evaluation: evaluation,
            data: {
              ...data,
              detected_faces: []
            }
          }
        })
      );
    },

    initTimelineSocket(salonId) {
      console.log("initing timeline socket");
      this.socket = io.connect(
        `${
          process.env.VUE_APP_TIMELINE_SOCKET
        }/salon/${salonId}?accessToken=${localStorage.getItem("AccessToken")}`
      );

      this.socket.on("error", error => {
        console.log("Timeline websocket error", error);
      });

      this.socket.on(`booking`, async ({ event, booking }) => {
        if (!this.isTodaySelected) return;
        console.log(event, booking);
        switch (event) {
          case "post":
            this.$store.dispatch("addBooking", booking);
            break;
          case "put":
            this.$store.dispatch("updateBooking", booking);
            break;
          case "delete":
            this.$store.dispatch("removeBooking", booking);
            break;
        }
      });
    },

    disconnectSocket() {
      this.socket.disconnect();
    }
  },

  async created() {
    this.$store.commit("updateSelectedSalon", this.user.listSalon);
    
  },

  watch: {
    async selectedSalon() {
      await this.fetchData();
      if (this.socket && this.socket.connected) this.disconnectSocket();
      if (this.selectedSalon && this.selectedSalon.id) {
        this.initTimelineSocket(this.selectedSalon.id);

        await this.$store.dispatch("setRecognitionSocket", this.selectedSalon.id);
        if (this.enableFaceRecognition) this.listenToRecognition();
      }
    },

    async dateNormal() {
      await this.fetchData();
    },

    enableFaceRecognition(isEnabled, _) {
      if (isEnabled) {
        if (
          this.recognition.lastCloseTime &&
          Date.now() - this.recognition.lastCloseTime < 90000
        ) {
          window.location.reload();
        } else this.listenToRecognition();
      } else this.disableRecognition();
    }
  }
};
</script>

<style lang="scss">
$cell-20-size: 1120px/3;

.timeline {
  .timeline-data {
    border: 1px solid #eee;
    border-bottom: 0;
    border-right: 0;
  }

  .cell {
    min-height: 50px;
    min-width: 280px;
    width: 280px;
    //border-right: 2px solid #f5f5f5;
    position: relative;
    border-bottom: 1px solid #f5f5f5;
    border-left: 1px solid #f5f5f5;

    .book-card {
      z-index: 98;
      position: absolute;
      top: 0;
    }
    &:hover {
      .book-card {
        z-index: 99;
      }
    }
  }

  .cell.is-disabled {
    background: #ddd;
    cursor: default;
    .columns {
      opacity: 0.3;
    }
  }

  .row {
    margin-bottom: 0 !important;
  }

  .data-table {
    overflow-x: auto;
    text-align: center;
    line-height: 2;
    .head {
      font-weight: bold;
      line-height: 3;
    }
    .cell {
      min-width: 280px;
      .columns {
        .column:nth-child(2) {
          border-left: 2px solid #fff;
        }
      }
    }
    .cell.is-cell-20-minutes {
      min-width: $cell-20-size;
    }
    .cell.placeholder-cell-20-minutes {
      min-width: 187px;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      border-radius: 0;
      background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: #47494e;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      background-color: #88b59c;
    }
  }
}
</style>