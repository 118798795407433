<template>
  <div class="container">
    <div class="person-container border">
      <div class="detected-faces-container">
        <b :style="{marginBottom: '10px'}">Ảnh nhận diện ({{ Math.round(data['confidence'] * 10000)/100 }}%)</b>

        <img
          class="detected-face"
          :src="'data:image/png;base64,' + data.detected_faces[0]"
          :style="{marginRight:'3px'}"
        >

        <!--<b :style="{marginTop: '10px'}">ĐCC: {{ Math.round(data['confidence'] * 10000)/100 }}%</b>-->
      </div>
    </div>
    <div class="cut-photo-container">
      <b>Ảnh trước đây ({{ data['info']['Name'] }}, {{ data['info']['Phone'] }})</b>
      <div class="cut-photos" v-viewer="{toolbar: true, url: 'data-source'}">
        <template v-for="image_url in data['image_urls'].slice(0,3)">
          <img :key="image_url" class="cut-photo" :src="image_url" :data-source="image_url">
        </template>
      </div>
      <div class="assessment-button-container">
        <div v-if="!Evaluation">
          <b-button
            size="is-small"
            class="assessment-button"
            @click="evaluateRecognition(true)"
            v-close-popover
          >Chính xác</b-button>
          <b-button
            class="assessment-button"
            @click="evaluateRecognition(false)"
            size="is-small"
          >Nhầm</b-button>
        </div>

        <p v-if="Evaluation">{{Evaluation.IsCorrect ? "Chính xác" : "Nhầm"}}</p>
      </div>
    </div>
    <span class="top-right">
      <button class="delete is-small" v-close-popover/>
    </span>
  </div>
</template>

<script>
export default {
  name: "RecognitionPopover",
  props: {
    data: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      Evaluation: null
    };
  },
  methods: {
    evaluateRecognition(isCorrect) {
      const evaluation = {
        IsCorrect: isCorrect
      };
      this.$emit("evaluate-recognition", { evaluation, data: this.data });
      //if (isCorrect == true)
    }
  },
  created() {
    const createdTime = Date.now();
    console.log(`recognition popover created at ${createdTime} with data`, this.data)
    console.log('info arrived on timeline after', createdTime - this.data.first_seen * 1000)
  }
};
</script>

<style lang="scss" scoped>
$image-size: 140px;

.person-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-width: 3px;
  margin-right: 10px;
  border: "1px solid green";
}

.container {
  // background: #bbdefb;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  max-width: $image-size * 4 + 20px;
}

.cut-photo-container {
  flex-direction: column;
  display: flex;
}

.cut-photos {
  flex-direction: row;
  display: flex;
}

.detected-faces-container {
  flex-direction: column;
  display: flex;
  margin-left: 5px;
}

.detected-faces-row {
  display: flex;
  flex-direction: row;
}

.assessment-button-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.assessment-button {
  width: 90px;
  margin-left: 5px;
}

.cut-photo {
  object-fit: cover; /* Do not scale the image */
  object-position: center; /* Center the image within the element */
  height: $image-size;
  width: $image-size;
  margin-top: 10px;
  margin-right: 2px;
  cursor: pointer;
}

.detected-face {
  height: $image-size;
  width: $image-size;
}

.top-right {
  position: absolute;
  right: -5px;
  top: -5px;
  opacity: 1;
  .delete {
    background: red;
    z-index: 97;
  }
}
</style>
