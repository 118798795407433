

const MAIN_API = process.env.VUE_APP_MAIN_API;
const NEW_API = process.env.VUE_APP_NEW_API;
const RECOGNITION_API = process.env.VUE_APP_RECOGNITION_API;
const CAMPAIGN_API = process.env.VUE_APP_CAMPAIGN_API;
const API_CHECKOUT = process.env.VUE_APP_API_CHECKOUT;

export default {
    MAIN_API,
    NEW_API,
    RECOGNITION_API,
    CAMPAIGN_API,
    API_CHECKOUT
}