

import axios from 'axios';

import apiConfig from '@/configs/api'

const state = {
    customers: {},
    specialCustomer: {},
    customerTag: {},
    campaigns: {}
}

const getters = {
    getCustomerByPhone: state => customerPhone => state.customers[customerPhone],
    getSpecialCustomerData: state => customerPhone => state.specialCustomer[customerPhone],
    getCustomerTagData: state => customerPhone => state.customerTag[customerPhone],
    getCampaignData: state => customerPhone => state.campaigns[customerPhone]
}

const actions = {
    async setCustomer({ commit }, { customerPhone, customer }) {
        // const { data: customer } = await axios.get(`${apiConfig.MAIN_API}/api/customer?phone=${customerPhone}`);

        commit('setCustomer', { customer, customerPhone })
    },

    async fetchSpecialCustomerData({ commit, state }, customerPhone) {
        if (state.specialCustomer[customerPhone]) return;
        const { data } = await axios.get(`${apiConfig.MAIN_API}/getErrorSpecial?customerPhone=${customerPhone}`);
        commit('setSpecialCustomerData', { customerPhone, data });
    },
    async fetchCustomerTagData({
        commit,
        state
    }, customerPhone) {
        if (state.customerTag[customerPhone]) return;
        const {
            data
        } = await axios.get(`${apiConfig.MAIN_API}/getCustomeTag?customerPhone=${customerPhone}`);
        commit('setCustomerTagData', {
            customerPhone,
            data
        });
    },

    async fetchCampaignData({ commit, state }, { customerPhone, bookingId }) {
        if (state.campaigns[customerPhone]) return;
        const { data } = await axios.post(`${apiConfig.CAMPAIGN_API}/api/validate-campaign/getCampaignCheckin`, { customerPhone, bookingId });
        console.log('campaign data', data.listCampaign);
        commit('setCampaignData', { customerPhone, data: data.listCampaign });
    }
}

const mutations = {
    setCustomer(state, { customer, customerPhone }) {
        state.customers[customerPhone] = customer;
    },
    setSpecialCustomerData(state, { customerPhone, data }) {
        state.specialCustomer = {
            ...state.specialCustomer,
            [customerPhone]: data
        }
    } ,
    setCustomerTagData(state, {
        customerPhone,
        data
    }) {
        state.customerTag = {
            ...state.customerTag,
            [customerPhone]: data
        }
    },
    setCampaignData(state, { customerPhone, data }) {
        state.campaigns = {
            ...state.campaigns,
            [customerPhone]: data
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
