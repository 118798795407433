
import VueDragAndDrop from 'vue-drag-and-drop';
import VueScrollTo from 'vue-scrollto';
import Notifications from 'vue-notification'
import VTooltip from 'v-tooltip'
import Autofocus from 'vue-autofocus-directive';
import VViewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';



import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';

import VueDragscroll from 'vue-dragscroll'
Vue.use(VueDragscroll)

/*
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)*/


import Buefy from 'buefy'
//import 'buefy/dist/buefy.css'

Vue.use(Buefy, {
    "css": true,
    "materialDesignIcons": true,
    "materialDesignIconsHRef": "//cdn.materialdesignicons.com/2.4.85/css/materialdesignicons.min.css"
})

Vue.use(VViewer, {
    defaultOptions: {
        zIndex: 999999
    }
})

Vue.use(VueDragAndDrop);


Vue.use(VTooltip, {
    defaultBoundariesElement: document.body
});

Vue.use(VueScrollTo, {
    container: ".data-table",
    duration: 500,
    easing: "ease",
    offset: 0,
    force: true,
    cancelable: true,
    onStart: false,
    onDone: false,
    onCancel: false,
    x: true,
    y: false
})
Vue.use(Notifications);

Vue.config.productionTip = false

Vue.config.errorHandler = Vue.config.warnHandler = err => {
    console.log('Exception: ', err)
}

Vue.directive('autofocus', Autofocus);

Vue.config.performance = process.env.NODE_ENV !== "production"

new Vue({
    store,
    router,
    render: h => h(App),
}).$mount('#app')
