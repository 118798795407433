

const bookingSuccess = (_this, customerName) => {
    _this.$notify({
        group: "foo",
        type: "success",
        title: "Hệ thống",
        text: `Đặt lịch thành công cho khách hàng ${
          customerName
        }`
    });
}

const changeBookingSuccess = (_this, customerName) => {
    _this.$notify({
        group: "foo",
        type: "success",
        title: "Hệ thống",
        text: `Đổi lịch thành công cho anh ${
            customerName
        }!`
    });
}

const changeBookingError = (_this, error) => {
    _this.$notify({
        group: "foo",
        type: "error",
        title: "Hệ thống",
        text: `Đổi lịch lỗi ${error}`
    });
}

const changeBookingDenyBillPrinted = (_this, customerName) => {
    _this.$notify({
        group: "foo",
        type: "error",
        title: "Hệ thống",
        text: `Đã in bill cho anh ${customerName}, không thể thực hiện tác vụ này!`
    });
}

const confirmChangeBookingTime = (_this, customerName) => {
    return new Promise((resolve, reject) => {
        _this.$buefy.dialog.confirm({
            title: "Hệ thống",
            message: `Anh ${
              customerName
            } đã đặt lịch rồi! bạn có muốn đổi lịch?`,
            cancelText: "Hủy",
            confirmText: "Đồng ý",
            type: "is-success",
            onConfirm: () => resolve(true),
            onCancel: () => resolve(false)
        });
    })
    
}

const generalError = (_this, error) => {
    _this.$notify({
        group: "foo",
        type: "error",
        title: "Hệ thống",
        text: `${error}`
    });
}
 
export default {
    bookingSuccess,
    changeBookingSuccess,
    changeBookingError,
    changeBookingDenyBillPrinted,
    confirmChangeBookingTime,
    generalError
}