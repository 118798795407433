


const WEBSOCKET_CONSUMER_PORT = 5678;
const WEBSOCKET_PRODUCER_PORT = 5679;

const getConsumerUrl = (hostUrl, consumerPort = WEBSOCKET_CONSUMER_PORT) => {
    return `wss://${hostUrl}:${consumerPort}`;
}

const getProducerUrl = (hostUrl, producerPort = WEBSOCKET_PRODUCER_PORT) => {
    return `wss://${hostUrl}:${producerPort}`;
}

export default {
    getConsumerUrl,
    getProducerUrl
}