

const removeVietnameseAccent = str => {
    str = str.toLowerCase();
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g,"a"); 
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g,"e"); 
    str = str.replace(/ì|í|ị|ỉ|ĩ/g,"i"); 
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g,"o"); 
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g,"u"); 
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g,"y"); 
    str = str.replace(/đ/g,"d");
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g," ");
    str = str.replace(/ + /g," ");
    str = str.trim(); 
    return str;
}

const transformNoteTooltip = note => {
    if (!note) return null;
    return note.split(',').join(', <br/>');
}

const transformSubHourIn = data => {
    if (!data.secondSubHourId) {
        if (data.subHourId) return { ...data, subHourId: data.subHourId + '' }
        else return data;
    } 
    let newData = {
        ...data,
        subHourId: 'sub2.' + data.secondSubHourId,
        secondSubHourId: null,
        parentSubHourId: data.subHourId
    }

    return transformStylistIn(newData);
}

const transformStylistIn = data => {
    if (data.stylistId) return { ...data, stylistId: data.stylistId + '' }
    else return data;
}

const transformSubHourOut = data => {
    if (!data.subHourId.includes('.')) return data;
    const secondSubHourId = parseInt(data.subHourId.split('.')[1]);
    const subHourId = Math.floor(secondSubHourId / 10);
    const newData = {
        ...data,
        subHourId,
        secondSubHourId
    }
    if (newData.parentSubHourId) delete newData.parentSubHourId;
    return transformStylistOut(newData);
}

const transformStylistOut = data => {
    if (!data.stylistId.includes('.')) return data;
    const stylistId = parseInt(data.stylistId.split('.')[1]);

    return {
        ...data,
        stylistId
    }
}

export default {
    removeVietnameseAccent,
    transformNoteTooltip,
    transformSubHourIn,
    transformSubHourOut,
    transformStylistOut,
    transformStylistIn
}