<template>
    <div class="row columns is-mobile is-gapless head container" @mousedown="$emit('mousedown')">
        <div
            v-if="is20Minutes"
            class="cell placeholder-cell-20-minutes"
        ></div>
        <div 
            v-for="(hour, i) in hours" 
            :key="hour.hour + i" 
            :id="`hour-${hour.hour}`"
            class="cell column hour-cell"
            :class="{'is-cell-20-minutes': is20Minutes}"
            data-dragscroll="true"
        >
            {{hour.hour}}
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

import timeUtil from '@/utils/time';

export default {
    name: "HourRow",
    props: ['is20Minutes'],
    data() {
        return {
            isFirstTime: true
        }
    },
    computed: {
        hours() {
            let hours = this.$store.getters.hours;
            return hours.filter(hour => (this.is20Minutes ? hour.is20Minutes : !hour.is20Minutes));
        }
    },
    methods: {
        gotoNow() {
            if (this.isFirstTime && this.hours && this.hours.length) {
                let goto = timeUtil.getNearest15Minutes();
                this.isFirstTime = false;
                const maxHourId = 'hour-' + this.hours[this.hours.length - 1].hour;
                if (goto.length == maxHourId.length && goto > maxHourId) goto = maxHourId;
                this.$scrollTo(document.getElementById(goto), 10, {})
            }
        }
    },
    mounted: function () {
        this.$nextTick(function () {
            this.gotoNow();
        })
    }
}
</script>

<style scoped>
.cell {
    font-size: 18px;
    max-height: 50px;
}

.cell.hour-cell {
    background-color: #fccd2b;
}

.container {
    cursor: pointer;
}
</style>
