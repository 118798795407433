
import timeUtil from '@/utils/time';
import storageUtil from '@/utils/storage';
import moment from 'moment';


const state = {
    timeline: null,
    timelineFilter: {
        date: new Date(),
        salon: {},
        enableFaceRecognition: storageUtil.getFromLocalStorage('enableFaceRecognition', true),
        minAccuracy: storageUtil.getFromLocalStorage('minAccuracy', 78)
    }
}

const getters = {
    timeline: state => state.timeline,
    timelineFilter: state => state.timelineFilter,
    selectedSalon: state => state.timelineFilter.salon,
    dateNormal: state => state.timelineFilter.date,
    isSlotOpen: state => (si, hi) => {
        return state.timeline[si][hi].length < 2;
    },
    isTodaySelected: state => timeUtil.isToday(state.timelineFilter.date),
    selectedMomentDate: state => moment(state.timelineFilter.date)
}

const actions = {
    resetTimeline({ commit, rootGetters: { stylists, hours } }) {
        let timeline = {};
        stylists.forEach(stylist => {
            timeline[stylist.id] = {};
            hours.forEach(hour => {
                timeline[stylist.id][hour.subHourId] = [];
            })
        })
        commit('setTimeline', timeline);
    },
    addToTimeline({ commit }, booking) {
        commit('addToTimeline', booking)
    },
    removeFromTimeline({ commit }, booking) {
        commit('removeFromTimeline', booking)
    },
    removeFromTimelineById({ rootGetters: { bookings }, dispatch }, bookingId) {
        const bks = bookings.filter(booking => booking.id == bookingId);
        if (bks.length) dispatch('removeFromTimeline', bks[0]);
    },
    async updateTimeline({ commit, rootGetters: { getBooking }}, booking) {
        const oldBooking = getBooking(booking.id);

        if ((booking.stylistId && booking.subHourId) && !(oldBooking.stylistId == booking.stylistId && oldBooking.subHourId == booking.subHourId)) {
            commit('removeFromTimeline', oldBooking);
            commit('addToTimeline', booking);
        }
    },
}

const mutations = {
    emptyTimeline(state) {
        state.timeline = null;
    },
    setTimeline(state, timeline) {
        state.timeline = timeline
    },
    addToTimeline(state, booking) {
        let [si, hi, bookingId] = [booking.stylistId, booking.subHourId, booking.id];
        if (state.timeline[si][hi].length == 1 && state.timeline[si][hi][0] == bookingId) {
            state.timeline[si][hi].splice(0, 1, bookingId)
        } else state.timeline[si][hi].push(bookingId);
    },
    removeFromTimeline(state, booking) {
        let [si, hi, bookingId] = [booking.stylistId, booking.subHourId, booking.id];
        for (let i = 0; i < state.timeline[si][hi].length; i++) {
            if (state.timeline[si][hi][i] === bookingId) {
                state.timeline[si][hi].splice(i, 1)
            }
        }
    },

    
    updateTimelineFilter(state, { name, value }) {
        state.timelineFilter[name] = value;
    },
    updateSelectedSalon(state, listSalon) {
        let salonId = parseInt(localStorage.getItem('salonId'))

        let selectedSalon = null;
        if (salonId) {
            for (let index = 0; index < listSalon.length; index++) {
                if (listSalon[index].id == salonId) {
                    selectedSalon = listSalon[index]
                }
            }
        }

        if (!selectedSalon && listSalon.length === 1) {
            selectedSalon = listSalon[0]
        }

        state.timelineFilter.salon = selectedSalon
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
