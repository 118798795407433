
import apiConfig from '@/configs/api'

import axios from 'axios';

import convertUtil from '@/utils/convert';
import storageUtil from '@/utils/storage';

const state = {
    services: [],
    products: [],
    experimentSalonIds: [],
    deleteBookingReasons: []
}

const getters = {
    services: state => state.services,
    products: state => state.products,
    isExperimentSalon: state => salonId => state.experimentSalonIds.includes(salonId),
    
    indexedServices: state => {
        const indexed = state.services.filter(s => s && s.serviceName).map(item => ({
            ...item,
            normalizedName: convertUtil.removeVietnameseAccent(item.serviceName.toLowerCase()) + ' ' + item.id,
            displayName: `${item.serviceName} (${item.id})`
        }))
        return indexed;
    },
    indexedProducts: state => {
        const indexed = state.products.map(item => ({
            ...item,
            normalizedName: convertUtil.removeVietnameseAccent(item.name.toLowerCase()) + ' ' + item.id,
            displayName: `${item.name} (${item.id})`
        }))
        return indexed;
    },
    getIndexedServiceById: (state, getters) => serviceId => {
        const results = getters.indexedServices.filter(item => item.id == serviceId);
        return results.length ? results[0] : {};
    },
    deleteBookingReasons: state => state.deleteBookingReasons
}

const actions = {
    async setServices({ commit }, salonId) {
        const getUrl = `${apiConfig.MAIN_API}/api/service/salon-services?salonId=${salonId}`;
        let services = await storageUtil.getStaticData(`services-${salonId}`, getUrl);
        
        commit('setServices', services)
    },
    async setProducts({ commit }, salonId) {
        const getUrl = `${apiConfig.API_CHECKOUT}/api/product/salon-products?salonId=${salonId}`;
        let products = await storageUtil.getStaticData(`products-${salonId}`, getUrl);
        commit('setProducts', products)
    },
    async setLinearFlowExperimentSalons({ commit }) {
        const { data: experimentSalonIds } = await axios.get(`${apiConfig.MAIN_API}/api/bill-service/linear-flow-experiment-salons`);
        commit('setExperimentSalonIds', experimentSalonIds);
    },
    async setDeleteBookingReasons({ commit }) {
        const { data: deleteBookingReasons } = await axios.get(`${apiConfig.MAIN_API}/api/booking/delete-reasons`);
        deleteBookingReasons.push({
            id: 0,
            value: 'Lý do khác:'
        })
        console.log('delete booking rs', deleteBookingReasons)
        commit('setDeleteBookingReasons', deleteBookingReasons);
    }
}

const mutations = {
    setServices(state, services) {
        state.services = Object.freeze(services);
    },
    setProducts(state, products) {
        state.products = Object.freeze(products);
    },
    setExperimentSalonIds(state, experimentSalonIds) {
        state.experimentSalonIds = experimentSalonIds;
    },
    setDeleteBookingReasons(state, deleteBookingReasons) {
        state.deleteBookingReasons = Object.freeze(deleteBookingReasons);
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
