

const state = {
    selectedBookingId: 0,
    isModalActive: {
        bookNote: false,
        printBill: false,
        bookForm: false,
        organizeSkinner: false,
        deleteBookingReason: false
    },
    isDragging: false,
    isEditingCustomer: false
}

const getters = {
    selectedBookingId: state => state.selectedBookingId,
    isModalActive: state => state.isModalActive,
    isDragging: state => state.isDragging,
    isEditingCustomer: state => state.isEditingCustomer
}

const actions = {

}

const mutations = {
    setModalActive(state, { type, isActive }) {
        state.isModalActive[type] = isActive;
    },
    openBookingModal(state, { bookingId, type }) {
        state.selectedBookingId = bookingId;
        state.isModalActive[type] = true;
    },
    setDragging(state, isDragging) {
        state.isDragging = isDragging;
    },
    setEditingCustomer(state, isEditingCustomer) {
        state.isEditingCustomer = isEditingCustomer;
    },
}

export default {
    state,
    getters,
    actions,
    mutations
}
