<template>
  <v-popover trigger="manual" :open="!!detectedInfo && !excludeRecognitions.includes(detectedInfo.recognition_id)" :autoHide="false" placement="left">
    
    <div class="booked-card">
      <div
        class="booked has-text-centered is-clickable guess-book level"
        @click="openPrintBill"
        :class="{'billed': booking.isMakeBill, 'salon-book': booking.isBookAtSalon, 'cell-shared': cellShared, 'is-dragging': isDragging}"
      >
        <div class="level-left">
          
          <div class="level-item no-padding signal">
            <span
              class="top"
              :class="{'booked-stylist': booking.isBookStylist, 'special': booking.customerScore <= 0}"
            ></span>
          </div>
          
          <div class="level-item customer-info">
            <div class="heading name-container">
              <b-icon class="is-special"  icon="star" size="is-small" v-if="booking.isSpecialCus"></b-icon>
              
              <b-icon
                class="is-angry"
                v-if="booking.completeBillTime === null && (minute*60 + second) >= 300 && !booking.isMakeBill"
                icon="alert"
                size="is-small"
              ></b-icon>
              
              <span class="customer-name" :class="{'billFinished': booking.completeBillTime != null}">{{ booking.customerName }}</span>

            </div>
            <p
              class="customer-phone"
            >
            <b class="new-customer-signal" v-if="!booking.totalBill">NEW</b>
            {{ booking.isShineMember && new Date(booking.memberEndTime) - new Date() > 0 ? booking.memberCode + " - ": "" }}{{ booking.customerPhone }}
            </p>
          </div>
          
        </div>
        <div class="level-right timer" v-if="!booking.completeBillTime">
          <span
            v-if="booking && booking.isCall"
            class="wait-time"
          >{{hour ? hour + ':' : ''}}{{ (minute >= 10 ? minute : '0' + minute) }}:{{ (second >= 10) ? second : '0' + second }}</span>
          <p v-if="!booking.isMakeBill">
            <span v-if="getPosition(booking)" class="call-position">{{getPosition(booking)}}</span>
            <b-checkbox
              class="check-time is-small"
              type="is-white"
              v-model="isCall"
              :disabled="!isTodaySelected"
            ></b-checkbox>
          </p>
        </div>
      </div>
      <span v-if="booking.note || booking.note1" class="note-button" @click="openBookingModal({ bookingId: booking.id, type: 'bookNote' })">
        <b-tooltip :label="booking.note || booking.note1" position="is-left" :multilined="true" type="is-info">
          <b-icon icon="message-bulleted" size="is-small" :style="{color: '#ff3860'}"></b-icon>
        </b-tooltip>
      </span>

      <button v-if="!booking.isMakeBill" class="delete is-small top-right" @click="openBookingModal({ bookingId: booking.id, type: 'deleteBookingReason' })"></button>
      
      <BookCard
        v-if="!cellShared"
        class="absolute"
        :booking="{subHourId: booking.subHourId, stylistId: booking.stylistId}"
        isInsideBooked
      ></BookCard>
    </div>
    <RecognitionPopover
      v-if="detectedInfo"
      :data="detectedInfo"
      slot="popover"
      @evaluate-recognition="onEvaluate"
    />
  </v-popover>
</template>

<script>
import moment from "moment";
import { mapGetters, mapMutations } from "vuex";
import apiConfig from "@/configs/api";
import axios from "axios";
import RecognitionPopover from "@/components/Timeline/RecognitionPopover";

import BookCard from "@/components/BookCard";
import convertUtil from "@/utils/convert";

export default {
  name: "BookedCard",
  components: {
    BookCard,
    RecognitionPopover
  },
  props: {
    bookingId: {
      type: Number,
      default: null
    },
    detectedInfo: {
      type: Object,
      default: null
    },
    cellShared: {
      type: Boolean,
      default: false
    }
  },

  data: function() {
    return {
      isCall: this.booking ? this.booking.isCall : false,
      lastTime: null,
      hour: 0,
      minute: 0,
      second: 0,
      excludeRecognitions: []
    };
  },

  computed: {
    ...mapGetters(["bookings", "hours", "stylists", "isTodaySelected", "shouldDeferAction", "isDragging"]),

    booking() {
      const booking = this.$store.getters.bookingMap[this.bookingId];
      if(booking) this.isCall = booking.isCall;
      return booking;
    }
  },

  methods: {
    ...mapMutations(['openBookingModal']),
    deleteBooking() {
      /*this.$buefy.dialog.prompt({
        title: "Hủy đặt lịch cho khách",
        message: `Bạn có chắc chắn muốn <b>hủy</b> đặt lịch cho anh ${
          this.booking.customerName
        }`,
        confirmText: "Đồng ý",
        type: "is-danger",
        hasIcon: true,
        inputAttrs: {
          placeholder: "Lý do hủy",
          maxlength: 120
        },

        onConfirm: async value => {
          const bookingId = this.booking.id;
          this.$store.dispatch("removeBooking", { id: bookingId });
          await axios.delete(`${apiConfig.MAIN_API}/api/booking?bookingId=${this.bookingId}&delNote=${value}`)
          this.$buefy.toast.open("Hủy thành công!");
        }
      });*/
    },

    getLastCallTime(booking) {
      return booking.isCallTimeModified || booking.isCallTime;
    },

    countTime() {
      if (this.booking.isCall) {
        var _this = this;
        setInterval(function() {
          let lastTime = moment(_this.lastTime).unix();
          let currentTime = moment().unix();
          let diffTime = currentTime - lastTime;
          let duration = moment.duration(diffTime * 1000, "milliseconds");
          _this.minute = duration.minutes();
          _this.second = duration.seconds();
          _this.hour = duration.hours(); 
        }, 1000);
      }
    },

    getPosition(booking) {
      let bookings = this.bookings.filter(item => item.subHourId === booking.subHourId && item.isCall && !item.isMakeBill);

      bookings.sort((a, b) => new Date(this.getLastCallTime(a)) - new Date(this.getLastCallTime(b)));
      
      let out = bookings.findIndex(item => item.id == booking.id)
      
      return out != -1 ? out + 1 : null;
    },
    openPrintBill(event) {
      if (this.isTodaySelected && !(event.target.className.includes('check') && this.isCall)) {
        this.openBookingModal({ bookingId: this.booking.id, type: 'printBill' })
      }
    },
    onEvaluate(event) {
      if (event.evaluation.IsCorrect) this.isCall = true;
      else {
        this.excludeRecognitions.push(event.data.recognition_id)
      }
      this.$emit("evaluate-recognition", event);
    }
  },

  watch: {
    async isCall(newValue) {
      if (newValue == this.booking.isCall) return;
      
      await this.$store.dispatch('updateIsCall', { bookingId: this.booking.id, isCall: this.isCall});

      this.lastTime = this.getLastCallTime(this.booking);
      this.countTime();
    },

    booking: {
      handler: function(newValue) {
        if (!newValue) return;
        this.isCall = newValue.isCall;
        this.lastTime = this.getLastCallTime(newValue);
        this.countTime();
      },
      deep: true
    }
  },

  created() {
    this.lastTime = this.getLastCallTime(this.booking);
    this.countTime();
  },

};
</script>

<style lang="scss">

.booked-card {
  max-width: 100%;
  width: 100%;
  position: relative;

  .note-button {
    position: absolute;
    bottom: 0;
    left: 0;
    
    height: 25px;
    &:focus,
    &:hover {
      background: none;
    }
    .icon {
      cursor: pointer;
    }
  }
  
  .top-right.delete {
    position: absolute;
    right: -5px;
    top: -5px;
    opacity: 0;
     
    background: red;
    z-index: 97;
    
  }
  &:hover {
    .top-right {
      opacity: 1;
    }

    .book-card {
      display: block;
      position: absolute;
      top: 50px !important;
      left: 0;
      right: 0;
      .wrap {
        opacity: 1;
      }
    }
  }
  .book-card {
    display: none;
  }
}

.booked.level {
  overflow: hidden;
  cursor: move;
  width: 100%;
  max-width: 100%;
  height: 49px;
  color: white;
  line-height: 1;
  font-size: 12px;
  position: relative;
  margin-bottom: 0;
  padding-bottom: 5px;

  .timer {
    padding-right: 3px;
  }
  .customer-phone {
    font-size: 15px;
    color: white;
    font-weight: normal;
  }
  p {
    display: block;
    margin-bottom: 5px;
  }
  p:last-child {
    margin-bottom: 0;
  }
  .level-item.no-padding {
    margin-right: 0;
  }

  .signal {
    width: 22px;
    height: 44px;
    position: relative;
    .top {
      position: absolute;
      width: 22px;
      left: 0;
      height: 22px;

      top: 0;
      width: 0;
      height: 0;
      border-left: 0 solid transparent;
      border-right: 22px solid transparent;
      border-top: 22px solid transparent;
    }
    .booked-stylist:before {
      position: absolute;
      top: -21px;
      left: 1px;
      content: "B";
    }
    .special {
      border-left: 0 solid transparent;
      border-right: 22px solid transparent;
      border-top: 22px solid #f56c3a;
    }
  }
  .check {
    border-color: white !important;
  }

  .call-position {
    margin-right: 7px;
    font-size: 15px;
  }

  .wait-time {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .name-container {
    max-width: 200px;
    overflow: hidden;
    margin-bottom: 3px;
    padding-top: 3px;
    padding-bottom: 2px;
  }
}

.booked.cell-shared {

  .customer-phone {
    font-size: 12px;
  }

  .timer {
    padding-right: 0px;
  }

  $signal-size: 14px;
  
  .signal {
    width: $signal-size;
    height: 44px;
    position: relative;
    .top {
      position: absolute;
      width: $signal-size;
      left: 0;
      height: $signal-size;

      top: 0;
      width: 0;
      height: 0;
      border-left: 0 solid transparent;
      border-right: $signal-size solid transparent;
      border-top: $signal-size solid transparent;
    }
    .booked-stylist:before {
      position: absolute;
      top: -$signal-size;
      left: 1px;
      content: "B";
    }
    .special {
      border-left: 0 solid transparent;
      border-right: $signal-size solid transparent;
      border-top: $signal-size solid #f56c3a;
    }
  }

  .call-position {
    margin-right: 3px;
    font-size: 12px;
  }

  .wait-time {
    font-size: 12px;
  }

  .name-container {
    max-width: 90px;
  }
}

.v-popover {
  display: flex;

  > .trigger {
    flex: 1 1 auto;
    max-width: 100%;
    outline: none;
  }
}

.popover-arrow {
  border-color: black;
  z-index: 1;
}

.customer-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.timer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.new-customer {
  color: white;
  margin-left: 5px;
}

.billFinished {
  text-decoration: line-through;
}

.booked:not(.cell-shared).drag-over.is-dragging {
  background: rgb(255, 228, 185);
}

.new-customer-signal {
  color: rgb(255, 56, 96);
  background: white; 
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
}
</style>
