<template >
  <div>
    <div class="app" v-if="isAuthenticated">
      <Navigation/>
      <router-view></router-view>
      <Footer/>
    </div>
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import { mapActions } from "vuex";
import axios from "axios";
import { setTimeout } from "timers";

import http from 'http';
import https from 'http';

axios.defaults.httpAgent = new http.Agent({ keepAlive: true });
axios.defaults.httpsAgent = new https.Agent({ keepAlive: true });

import apiConfig from '@/configs/api';
/*
setInterval(async () => {
  const start = Date.now();
  const res = await axios.put('http://gitlab.30shine.com:6699/api/booking?bookingId=5461928', {
    customerPhone: "0839684434",
    dateBook: "2019-06-03",
    hourId: 34,
    isBookStylist: true,
    salonId: 2,
    stylistId: 261,
    subHourId: 341
  });
  console.log('take', Date.now() - start);
}, 6000)
setTimeout(() => {
  setInterval(async () => {
    const start = Date.now();
    const res = await axios.put('http://gitlab.30shine.com:6699/api/booking?bookingId=5461928', {
      customerPhone: "0839684434",
      dateBook: "2019-06-03",
      hourId: 35,
      isBookStylist: true,
      salonId: 2,
      stylistId: 2751,
      subHourId: 350
    });
    console.log('take', Date.now() - start);
  }, 6000)
}, 3000)


*/


export default {
  name: "App",
  components: {
    Navigation,
    Footer
  },
  data() {
    return {
      isAuthenticated: false
    };
  },
  methods: {
    ...mapActions(["setUser"])
  },
  async created() {
    try {
      const creds = await ssoLogin();

      // axios.defaults.headers.common["Authorization"] = creds.AccessToken;

      axios.interceptors.request.use(function (config) {
        config.headers['Authorization'] = localStorage.getItem('AccessToken');
        return config;
      }, function (error) {
        return Promise.reject(error);
      });

      // Add a response interceptor
      axios.interceptors.response.use(response => {
        return response;
      }, error => {
        if (error.response.status == 401) {
          this.$notify({
              group: "foo",
              type: "error",
              title: "Hệ thống",
              text: "Lỗi phân quyền: " + error.request.responseURL + ". Vui lòng thoát ra đăng nhập lại"
          });
        }
        return Promise.reject(error);
      });


      await this.setUser();
      this.isAuthenticated = true;
      // remove iframe
      /*var iframes = document.querySelectorAll('iframe');
      for (var i = 0; i < iframes.length; i++) {
          iframes[i].parentNode.removeChild(iframes[i]);
      }*/
    } catch (e) {}
  }
};
</script>

<style lang="scss">
@import "./assets/scss/custom";

// Import Bulma's core
@import "~bulma/sass/utilities/_all";

$dark: #000;
// Setup $colors to use as bulma classes (e.g. 'is-twitter')

$success: #fcd344;
$primary: #fcd344;

$colors: (
  "white": (
    $white,
    $black
  ),
  "black": (
    $black,
    $white
  ),
  "light": (
    $light,
    $light-invert
  ),
  "dark": (
    $dark,
    $dark-invert
  ),
  "primary": (
    $primary,
    $primary-invert
  ),
  "info": (
    $info,
    $info-invert
  ),
  "success": (
    $success,
    $success-invert
  ),
  "warning": (
    $warning,
    $warning-invert
  ),
  "danger": (
    $danger,
    $danger-invert
  )
);

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
@import "./assets/scss/app";
@import "./assets/scss/popover";
</style>
