
import axios from 'axios';

import apiConfig from '@/configs/api'

const state = {
    recognitionError: null,
    recognitionSocket: null
}

const getters = {
    recognitionError: state => state.recognitionError,
    recognitionSocket: state => state.recognitionSocket
}

const actions = {
    async setRecognitionSocket({ commit }, salonId) {
        // const { data } = await axios.get(`${apiConfig.RECOGNITION_API}/stream?salonId=${salonId}&fields=RecognitionSocket`);
        // commit('setRecognitionSocket', data['RecognitionSocket']);
        commit('setRecognitionSocket', null);
    },
    setRecognitionError({ commit }, error) {
        commit('setRecognitionError', error)
    },
}

const mutations = {
    setRecognitionError(state, error) {
        state.recognitionError = error;
    },
    setRecognitionSocket(state, recognitionSocket) {
        state.recognitionSocket = Object.freeze(recognitionSocket)
    }
}

export default {
    state,
    actions,
    mutations,
    getters
}