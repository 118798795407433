

const state = {
    billMap: {}

}

const getters = {
    billMap: state => state.billMap,
    billByBookingId: state => {
        let m = {};
        Object.keys(state.billMap).forEach(billId => {
            const bill = state.billMap[billId];
            m[bill.bookingId] = bill;
        })
        return m;
    }
}

const actions = {

}

const mutations = {
    setBillMap(state, bill) {
        state.billMap = {
            ...state.billMap,
            [bill.id]: bill
        }
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
