import Vue from 'vue'
import Router from 'vue-router'
import Home from '../views/Home'
const SearchCustomer = () => import('../views/SearchCustomer')

Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'home',
            component: Home
        },
        {
            path: '/khach-hang/tim-kiem',
            name: 'search-customer',
            component: SearchCustomer
        }
    ]
})