import apiConfig from '@/configs/api'

import axios from 'axios';

import storageUtil from '@/utils/storage'

const state = {
    hours: []
}

const getters = {
    hours: state => state.hours,
    hourMap: state => {
        let m = {};
        state.hours.forEach((hour, index) => {
            m[hour.subHourId] = index;
        })
        return m;
    },
    hourCount: state => state.hours.length,
    
    getDetailHour: (state, getters) => subHourId => state.hours[getters.hourMap[subHourId]],

    getHourIdFromSubHour: (state, getters) => subHourId => state.hours[getters.hourMap[subHourId]].hourId
}

const actions = {
    async setHours({ commit, dispatch }, salonId) {
        // let { data: hours } = await axios.get(`${apiConfig.MAIN_API}/api/book-hour?salonId=${salonId}`)
        let hours = await storageUtil.getStaticData(`hours-${salonId}`, `${apiConfig.MAIN_API}/api/book-hour?salonId=${salonId}`)
        hours = hours.map(hour => ({ ...hour, subHourId: hour.subHourId + ''}))
        commit('addHours', hours);
        await dispatch('setHours20Minutes', salonId);
    },
    async setHours20Minutes({ commit }, salonId) {
        // let { data: hours20Minutes } = await axios.get(`${apiConfig.MAIN_API}/api/book-hour/twenty-minutes?salonId=${salonId}`)
        let hours20Minutes = await storageUtil.getStaticData(`hours20Minutes-${salonId}`, `${apiConfig.MAIN_API}/api/book-hour/twenty-minutes?salonId=${salonId}`)
        commit('addHour20Minutes', hours20Minutes)
    }
}

const mutations = {
    addHours(state, hours) {
        state.hours = Object.freeze(hours)
    },
    addHour20Minutes(state, hours20Minutes) {
        hours20Minutes = hours20Minutes.map(item => ({...item, is20Minutes: true, subHourId: 'sub2.' + item.secondSubHourId}))
        state.hours = [...state.hours, ...hours20Minutes];
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
