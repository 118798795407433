import axios from 'axios';
import apiConfig from '@/configs/api'



const state = {
    stylists: [],
    skinners: [],
    checkins: [],
    fastStylists: [],
    normalStylists: [],
    skinnerStatus: {}
}

const getters = {
    stylists: state => state.stylists,
    fastStylists: state => state.fastStylists,
    normalStylists: state => state.normalStylists,
    skinners: state => state.skinners,
    checkins: state => state.checkins,
    stylistMap: state => {
        let m = {};
        state.stylists.forEach((stylist, index) => {
            m[stylist.id] = index;
        })
        return m;
    },
    skinnerMap: state => {
        let m = {};
        state.skinners.forEach((skinner, index) => {
            m[skinner.id] = index;
        })
        return m;
    },
    stylistCount: state => state.stylists.length,
    getDetailStylist: (state, getters) => stylistId => state.stylists[getters.stylistMap[stylistId]],
    getDetailSkinner: (state, getters) => skinnerId => state.skinners[getters.skinnerMap[skinnerId]],
    getTeamSkinnerByStylistId: (state, getters) => stylistId => {
        const teamId = state.stylists[getters.stylistMap[stylistId]].teamId;
        const results = state.skinners.filter(item => item.teamId == teamId);
        return results.length ? results[0] : null;
    },
    getCheckinById: state => checkinId => {
        const results = state.checkins.filter(item => item.id == checkinId);
        return results.length ? results[0] : null;
    },
    getSelectedCheckin: (state, getters) => () => {
        let checkinId;
        const cachedId = localStorage.getItem('checkinId');
        if (cachedId) {
            checkinId = parseInt(cachedId);
            return getters.getCheckinById(checkinId);
        } else return null;
    },
    skinnerStatus: state => state.skinnerStatus
}

const actions = {
    async getStylists({ commit }, { salonId, date }) {
        const { data: stylists } = await axios.get(`${apiConfig.MAIN_API}/api/get-staff/get-stylist?salonId=${salonId}&workDate=${date.format("YYYY-MM-DD")}`)

        for (let i = 0; i < stylists.length; i++) {
            if (stylists[i].secondSubWorkHour) stylists[i].workHour = stylists[i].secondSubWorkHour.split(',').map(hour => 'sub2.' + hour).join(',')
            stylists[i].isActive = {};
            stylists[i].workHour.split(',').forEach(subHourId => {
                stylists[i].isActive[subHourId] = true;
            })
            stylists[i].id = stylists[i].id + '';
        }
        
        commit('setStylists', stylists)
    },
    async setSkinners({ commit }, { salonId, date }) {
        const { data: skinners } = await axios.get(`${apiConfig.MAIN_API}/api/get-staff/get-skinner?salonId=${salonId}&workDate=${date.format("YYYY-MM-DD")}`)
        commit('setSkinners', skinners)
    },
    async setCheckins({ commit }, { salonId, date }) {
        const { data: checkins } = await axios.get(`${apiConfig.MAIN_API}/api/get-staff/get-checkins?salonId=${salonId}&workDate=${date.format("YYYY-MM-DD")}`)
        commit('setCheckins', checkins)
    },
    async setSkinnerStatus({ commit }, skinnerIdString) {
        if (!skinnerIdString) return;
        const { data: bills } = await axios.get(`${apiConfig.MAIN_API}/api/bill-service/skinner-status?skinnerIds=${skinnerIdString}`);
        commit('setSkinnerStatus', bills)
    }
}

const mutations = {
    setStylists(state, stylists) {
        state.stylists = Object.freeze(stylists);
        state.fastStylists = stylists.filter(stylist => stylist.secondSubWorkHour);
        state.normalStylists = stylists.filter(stylist => !stylist.secondSubWorkHour);
    },
    setSkinners(state, skinners) {
        state.skinners = Object.freeze(skinners);
    },
    setCheckins(state, checkins) {
        state.checkins = Object.freeze(checkins);
    },
    setSkinnerStatus(state, bills) {
        let temp = {}
        bills.forEach(bill => {
            temp[bill.staffHairMassageId] = bill;
        })

        state.skinnerStatus = {
            ...state.skinnerStatus,
            ...temp
        }

        console.log('new skinner status', state.skinnerStatus)
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
