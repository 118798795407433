<template>
  <header>
    <nav class="navbar is-dark" role="navigation" aria-label="dropdown navigation">
      <div class="navbar-brand">
        <div class="navbar-item logo">
          <img src="https://erp.30shine.com/Assets/images/ic_logo_white.png" alt="30shine">
        </div>
      </div>
      <div class="navbar-menu">
        <div class="navbar-start">
          <router-link class="navbar-item" to="/">Timeline</router-link>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-arrowless" to="/">Khách hàng</a>
            <div class="navbar-dropdown">
              <a class="navbar-item" :href="`${currentTimeline}/khach-hang/tim-kiem.html`" target="_blank" rel="noreferrer">Tìm kiếm</a>
              <a class="navbar-item" :href="`${currentTimeline}/khach-hang/hoa-chat`" target="_blank" rel="noreferrer">CSKH</a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-arrowless" to="/">Booking</a>
            <div class="navbar-dropdown">
              <a class="navbar-item" :href="`${currentTimeline}/admin/listing-booking-test.html`" target="_blank" rel="noreferrer">Danh sách</a>
              <a class="navbar-item" :href="`${currentTimeline}/tracking/danh-sach/yeu-cau-dac-biet-cua-khach.html`" target="_blank" rel="noreferrer">Danh sách yêu cầu đặc biệt</a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-arrowless" to="/">Hóa đơn</a>
            <div class="navbar-dropdown">
              <a class="navbar-item" :href="`${currentTimeline}/dich-vu/pending.html`" target="_blank" rel="noreferrer">Checkout (Pending)</a>
              <a class="navbar-item" :href="`${currentTimeline}/dich-vu/danh-sach.html`" target="_blank" rel="noreferrer">Danh sách</a>
            </div>
          </div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link is-arrowless" to="/">Báo cáo</a>
            <div class="navbar-dropdown">
              <!--<router-link class="navbar-item" to="/khach-hang/tim-kiem">Tìm kiếm</router-link>-->
              <a class="navbar-item" :href="`${currentTimeline}/admin/bao-cao/doanh-so-my-pham.html`" target="_blank" rel="noreferrer">Mỹ phẩm</a>
              <a class="navbar-item" :href="`${currentTimeline}/checkin/luong-doanh-so.html`" target="_blank" rel="noreferrer">Doanh số checkin</a>
            </div>
          </div>
        </div>
        <div class="navbar-end">
          <div class="navbar-item" v-if="this.user">Chào {{ this.user.fullName }}</div>
          <div class="navbar-item has-dropdown is-hoverable">
            <a href="#" class="navbar-link has-icons-left">
              <b-icon icon="account-location" size="is-small"></b-icon>
            </a>

            <div class="navbar-dropdown is-right" v-if="this.user">
              <a href="#" @click="logout()" class="navbar-item">Thoát</a>
            </div>
            <div class="navbar-dropdown is-right" v-else>
              <router-link to="/login" class="navbar-item">Đăng nhập</router-link>
            </div>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import { mapGetters } from "vuex";

import linkConfig from '@/configs/link';

export default {
  name: "Navigation",

  data() {
    return {
      isActive: false,
      currentTimeline: linkConfig.CURRENT_TIMELINE
    };
  },
  methods: {
    logout() {
      localStorage.clear();
      ssoLogout();
    }
  },
  computed: {
    ...mapGetters(["user"])
  }
};
</script>

<style lang="scss" scoped>
.navbar-dropdown, .navbar {
  z-index: 9;
}

.navbar {
  height: 60px;
}

.navbar-item {
  font-size: 18px;
}
.navbar-item img {
  max-height: 45px;
}

.navbar-item.logo {
  cursor: pointer;
}
</style>
   