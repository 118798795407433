import axios from 'axios';

const getFromLocalStorage = (key, def) => {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    else return def;
}

const getStaticData = async (storageKey, getUrl) => {
    let data =  localStorage.getItem(storageKey);
    if (data) data = JSON.parse(data)
    else {
        console.log('fetching ' + storageKey);
        const response = await axios.get(getUrl);
        data = response.data;
        localStorage.setItem(storageKey, JSON.stringify(data));
    }
    return data;
}

export default {
    getFromLocalStorage,
    getStaticData
}