<template>
  <div class="column is-1 stylists" v-if="fastStylists.length || normalStylists.length">
    <div class="data-define">
      <!--<div class="cell axis-description"></div>-->
      <div v-if="fastStylists.length > 0">
        <div class="cell section-description">Khung giờ 20 phút</div>
        <div
          
          class="cell stylist"
          v-for="stylist in fastStylists"
          :key="stylist.id"
          v-bind:class="{'is-star': stylist.score > 2 }"
        >
          <span class="account-type">{{ stylist.teamId }}</span>
          <span class="account-name">{{ stylist.fullName }}</span>
          <span class="count">{{ completedBillByStylist[stylist.id] || 0 }}</span>
        </div>
      </div>
      <div v-if="normalStylists.length > 0">
        <div class="cell section-description">Khung giờ 15 phút</div>
        <div
          class="cell stylist"
          v-for="stylist in normalStylists"
          :key="stylist.id"
          v-bind:class="{'is-star': stylist.score > 2 }"
        >
          <span class="account-type">{{ stylist.teamId }}</span>
          <span class="account-name">{{ stylist.fullName }}</span>
          <span class="count">{{ completedBillByStylist[stylist.id] || 0 }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "StylistColumn",
  computed: {
    ...mapGetters(["completedBillByStylist", "fastStylists", "normalStylists"])
  },
  props: ['isFast']
};
</script>

<style scoped lang="scss">
.timeline {
  .cell.stylist {
    position: relative;
    .count {
      position: absolute;
      top: 5px;
      right: 5px;
      color: #42cabf;
      font-weight: bold;
    }
  }
}

.stylists {
  min-width: 280px;
}

.is-star {
  background: #f56c3a;
}

.cell {
  min-height: 50px;
  min-width: 280px;
  border-right: 1px solid #f5f5f5;
  position: relative;
  border-bottom: 1px solid #f5f5f5;
  .account-type {
    top: 5px;
    left: 10px;
    font-size: 13px;
    font-weight: bold;
    position: absolute;
  }
  .account-name {
    bottom: 5px;
    left: 10px;
    font-weight: bold;
    position: absolute;
  }
}

.cell.axis-description {
  background: url("../../assets/img/head-time.png") center no-repeat;
  background-size: 100% auto;
  -webkit-box-shadow: 0 3px 7px -2px #d8d8d8;
  -moz-box-shadow: 0 3px 7px -2px #d8d8d8;
  box-shadow: 0 3px 7px -2px #d8d8d8;
}

.cell.section-description {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 50px;
  background-color: #fccd2b
}
</style>
