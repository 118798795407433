import moment from 'moment';

const getNearest15Minutes = () => {
    const now = moment().subtract(15, 'minutes');
    let currentHour = now.hours();
    let currentMinute = now.minutes();
    let minute = currentMinute - currentMinute % 15;
    let result = 'hour-' + currentHour + 'h' + (minute < 10 ? "0" + minute : minute);
    return result;
}

const isToday = (date) => {
    const today = new Date();
    return date.getDate() == today.getDate() &&
        date.getMonth() == today.getMonth() &&
        date.getFullYear() == today.getFullYear()
}

export default { 
    getNearest15Minutes,
    isToday
}
