<template>
  <div class="book-card" draggable="false" v-if="!isInsideBooked || !isDragging" :class="{'is-dragging': isDragging}">
    <div class="wrap" v-if="!isDragging">
      <div 
        v-if="$store.getters.isTodaySelected" 
        class="action button is-text print" 
        @click="openBookingModal({ bookingId: mockBookingId, type: 'printBill'})">
        In phiếu
      </div>
      <div
        class="action button is-text book"
        @click="openBookingModal({ bookingId: mockBookingId, type: 'bookForm'})"
        :style="{width: isTodaySelected ? '50%' : '100%' }"
      >
      Book lịch
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "BookCard",
  props: {
    booking: {
      type: Object,
      default: null
    },
    isInsideBooked: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapMutations(['openBookingModal']),
  },
  computed: {
    ...mapGetters(['isTodaySelected', 'isDragging']),
    mockBookingId() {
      return this.booking.stylistId + '-' + this.booking.subHourId;
    }
  }
};
</script>

<style lang="scss" scoped>
.book-card {
  width: 100%;
  height: 100%;
  padding: 0;
  z-index: 98;
  .wrap {
    opacity: 0;
    background-color: cornsilk;
    width: 100%;
    height: 100%;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12), 0 1px 1px rgba(0, 0, 0, 0.24);
    .action {
      width: 50%;
      text-decoration: none;
      height: 3rem;
    }
    .print {
      background: #ffd200;
    }
    .book {
      background: #a5e200;
    }
  }
  &:hover {
    .wrap {
      opacity: 1;
    }
  }

}

.book-card.drag-over.is-dragging {
  background: #88b59c;
}
</style>
