import Vuex from 'vuex';
import Vue from 'vue';

import booking from './modules/booking'
import hour from './modules/hour'
import staff from './modules/staff'
import timeline from './modules/timeline'
import auth from './modules/auth'
import recognition from './modules/recognition'
import staticModule from './modules/static'
import customer from './modules/customer'
import appState from './modules/appState'
import bill from './modules/bill'

Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
        booking,
        hour,
        staff,
        timeline,
        auth,
        recognition,
        staticModule,
        customer,
        appState,
        bill
    }
});

export default store;